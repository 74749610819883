import React from 'react';

import { Button, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface productionFormat {
    width: number;
    height: number;
    inAnimation: boolean;
    responsive: boolean;
    index: number;
    selected: number;
    updateFocus: Function;
    selectedHeight: string;
    deselectedHeight: string;
    initialHeight: string;
}

export function Production(props: productionFormat) {
    return (
        <Box
            width="100%"
            height="100%"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'space-between',
                    flexGrow: 1,
                }}
            >
                <Box
                    sx={{
                        mx: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        flexGrow: 1,
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            backgroundColor: 'rgba(0,0,0,.5)',
                            boxShadow: '0 0 5px 10px rgba(0,0,0,.5)',
                            px: props.responsive ? 3 : 0,
                            py: 0,
                            width: props.responsive ? 'inherit' : '80%',
                        }}
                    >
                        <Typography
                            variant="h4"
                            align="center"
                            noWrap={props.responsive}
                            color="#FFF"
                            gutterBottom
                            fontSize={props.responsive ? 48 : props.width / 8}
                            fontFamily="Roboto"
                            sx={{
                                textShadow:
                                    '0 0 10px #000, 0 0 10px #000, 0 0 5px #000, 0 0 3px #000, 0 0 2px #000, 0 0 1px #000',
                                overflow: 'visible',
                                my: 0,
                            }}
                        >
                            {"Metric's Sound Studio"}
                        </Typography>
                    </Paper>
                    <Paper
                        elevation={4}
                        sx={{
                            width: props.responsive ? '450px' : '100%',
                            backgroundColor: 'rgba(0,0,0,.5)',
                            boxShadow: '0 0 5px 10px rgba(0,0,0,.5)',
                        }}
                    >
                        <Typography
                            variant="body1"
                            align="center"
                            color="#FFF"
                            width={props.responsive ? '400px' : '95%'}
                            gutterBottom
                            fontSize={
                                props.responsive ? 20 : 45 - 10000 / props.width
                            }
                            fontFamily="Roboto"
                            sx={{
                                textShadow:
                                    '0 0 15px #000,0 0 15px #000, 0 0 10px #000, 0 0 5px #000, 0 0 3px #000, 0 0 2px #000, 0 0 1px #000',
                                overflow: 'visible',
                                my: props.height / 500,
                                mx: 'auto',
                            }}
                        >
                            Immerse yourself in a world of sound within our
                            studio, where every note is captured with pristine
                            clarity. Our stage-sized rehearsal space offers
                            ample room for you to unleash your musical prowess,
                            accommodating up to 48 simultaneous inputs for your
                            recorded sessions.
                        </Typography>
                    </Paper>
                    <Paper elevation={6}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#E49B0F',
                                color: '#000',
                                '&:hover': {
                                    backgroundColor: '#000',
                                    color: '#FFF',
                                },
                            }}
                            href="mailto:patrick@metricsounds.com"
                            // disabled={props.inAnimation}
                        >
                            <Typography
                                variant="body2"
                                align="center"
                                fontSize={
                                    props.responsive
                                        ? 20
                                        : 43 - 10000 / props.width
                                }
                            >
                                {'Inquire Now'}
                            </Typography>
                        </Button>
                    </Paper>
                </Box>
                <Typography
                    variant="h3"
                    align="center"
                    noWrap={props.responsive}
                    color="#FFF"
                    width="100%"
                    fontSize={props.height / 18}
                    fontFamily="Orbitron"
                    sx={{
                        textShadow:
                            '0 0 15px #000, 0 0 15px #000, 0 0 10px #000, 0 0 10px #000, 0 0 5px #000, 0 0 3px #000, 0 0 2px #000, 0 0 1px #000',
                        overflow: 'hidden',
                        my: props.responsive ? props.height / 1000 : '5px',
                        alignSelf: 'flex-end',
                        opacity: props.selected >= props.index ? 0 : 1,
                        WebkitTransition: props.responsive
                            ? 'opacity 590ms linear'
                            : 'opacity 290ms linear',
                        MozTransition: props.responsive
                            ? 'opacity 590ms linear'
                            : 'opacity 290ms linear',
                        transition: props.responsive
                            ? 'opacity 590ms linear'
                            : 'opacity 290ms linear',
                        willChange: 'opacity',
                    }}
                >
                    Production
                </Typography>
            </Box>
        </Box>
    );
}
