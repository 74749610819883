import React, { Component } from 'react';
import Box from '@mui/material/Box';
import YD1 from '../../assets/DesktopAssets/Yellow1.jpg';
import YD2 from '../../assets/DesktopAssets/Yellow2.jpg';
import YD3 from '../../assets/DesktopAssets/Yellow3.jpg';
import YD4 from '../../assets/DesktopAssets/Yellow4.jpg';
import YD5 from '../../assets/DesktopAssets/Yellow5.jpg';
import YM1 from '../../assets/MobileAssets/Yellow1.jpg';
import YM2 from '../../assets/MobileAssets/Yellow2.jpg';
import YM3 from '../../assets/MobileAssets/Yellow3.jpg';
import YM4 from '../../assets/MobileAssets/Yellow4.jpg';
import YM5 from '../../assets/MobileAssets/Yellow5.jpg';
import { Tab } from '../.';

import { responsiveLimit } from '../../constants/styles';

const YellowDesktop = [YD1, YD2, YD3, YD4, YD5, YD2, YD3, YD4, YD5];
const YellowMobile = [YM1, YM2, YM3, YM4, YM5, YM2, YM3, YM4, YM5];

export class Wrapper extends Component {
  state = {
    width: 0,
    height: 0,
    selected: 0,
    animationState: 0,
    xDown: 0,
    yDown: 0,
    initialTime: 0,
    inAnimation: false,
    imageList:
      window.innerWidth > responsiveLimit
        ? YellowDesktop
        : YellowMobile,
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('wheel', this.enforceWheel);
    window.addEventListener('touchstart', this.enforceSwipeStart);
    window.addEventListener('touchend', this.enforceSwipeEnd);
    window.addEventListener('wheel', this.preventScroll, {
      passive: false,
    });
    window.addEventListener('touchmove', this.preventScroll, {
      passive: false,
    });
    window.addEventListener('onscroll', this.preventScroll, {
      passive: false,
    });
  }

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('wheel', this.enforceWheel);
    window.removeEventListener('touchstart', this.enforceSwipeStart);
    window.removeEventListener('touchend', this.enforceSwipeEnd);
    window.removeEventListener('wheel', this.preventScroll);
    window.removeEventListener('touchmove', this.preventScroll);
    window.removeEventListener('onscroll', this.preventScroll);
  }

  inRange(x: number, min: number, max: number) {
    return x >= min && x <= max;
  }

  difference(a: number, b: number) {
    return Math.abs(a - b);
  }

  updateFocus = (index: number) => {
    if (this.state.inAnimation) return;
    let imgRotation: string[] = [];
    for (
      let i: number = Math.max(
        this.state.selected,
        this.state.imageList.length - 8
      );
      i < index + 4;
      i++
    ) {
      imgRotation.push(this.state.imageList[i]);
    }
    this.setState({
      selected: index,
      imageList: [...this.state.imageList, ...imgRotation],
      inAnimation: true,
    });
    setTimeout(
      () => {
        this.setState({ inAnimation: false });
      },
      window.innerWidth > responsiveLimit ? 600 : 300
    );
  };

  enforceWheel = (event: WheelEvent) => {
    let direction: number;
    if (![-1, 1].includes((direction = Math.sign(event.deltaY))))
      return;
    if ((direction += this.state.selected) < 0) return;
    if (Math.sign(event.deltaY) > 0) this.updateFocus(direction);
    else this.updateFocus(0);
  };

  enforceSwipeStart = (event: TouchEvent) => {
    this.setState({
      xDown: event.changedTouches[0].screenX,
      yDown: event.changedTouches[0].screenY,
      initialTime: Date.now(),
    });
  };

  enforceSwipeEnd = (event: TouchEvent) => {
    let direction: number;
    direction =
      this.state.yDown > event.changedTouches[0].screenY ? 1 : -1;
    if ((direction += this.state.selected) < 0) return;
    if (
      this.difference(
        this.state.yDown,
        event.changedTouches[0].screenY
      ) /
        this.difference(
          this.state.xDown,
          event.changedTouches[0].screenX
        ) <=
        1.4 ||
      this.difference(
        this.state.yDown,
        event.changedTouches[0].screenY
      ) <
        this.difference(this.state.initialTime, Date.now()) * 0.7
    ) {
      return;
    }
    if (this.state.yDown > event.changedTouches[0].screenY)
      this.updateFocus(direction);
    else this.updateFocus(0);
  };

  preventScroll = (event: Event) => event.preventDefault();

  render() {
    // State values used in render
    const { width, height, selected, imageList, inAnimation } =
      this.state;

    // Checks for mobile user
    const responsive = width > responsiveLimit;

    // Determine Hights
    //const selectedHeight = responsive ? 'calc(100% - 360px)' : '61%';
    //const deselectedHeight = responsive ? '120px' : '13%';
    const selectedHeight = '70%';
    const deselectedHeight = '10%';
    const initialHeight = '0%';

    return (
      <Box
        sx={{
          width: { width },
          height: { height },
        }}
      >
        <Box
          sx={{
            width: { width },
            height: { height },
            zIndex: 2,
            position: 'relative',
            WebkitTransform:
              selected === 0 ? 'translateY(0%)' : 'translateY(-100%)',
            WebkitTransition: responsive
              ? '-webkit-transform 490ms ease-in'
              : '-webkit-transform 290ms ease-in',
            MozTransform:
              selected === 0 ? 'translateY(0%)' : 'translateY(-100%)',
            MozTransition: responsive
              ? '-moz-transform 490ms ease-in'
              : '-moz-transform 290ms ease-in',
            transform:
              selected === 0 ? 'translateY(0%)' : 'translateY(-100%)',
            transition: responsive
              ? 'transform 490ms ease-in'
              : 'transform 290ms ease-in',
            willChange: 'transform',
          }}
        >
          <Tab
            responsive={responsive}
            width={width}
            height={height}
            index={0}
            image={imageList[0]}
            inAnimation={inAnimation}
            selected={selected}
            selectedHeight={selectedHeight}
            deselectedHeight={deselectedHeight}
            initialHeight={initialHeight}
            updateFocus={this.updateFocus}
          />
        </Box>
        <Box
          sx={{
            width: { width },
            height: { height },
            zIndex: 1,
            position: 'relative',
            WebkitTransform:
              selected === 0
                ? 'translateY(0%)'
                : `translateY(${-100 - 70 * (selected - 1)}%)`,
            WebkitTransition: responsive
              ? '-webkit-transform 590ms linear'
              : '-webkit-transform 290ms linear',
            MozTransform:
              selected === 0
                ? 'translateY(0%)'
                : `translateY(${-100 - 70 * (selected - 1)}%)`,
            MozTransition: responsive
              ? '-moz-transform 590ms linear'
              : '-moz-transform 290ms linear',
            transform:
              selected === 0
                ? 'translateY(0%)'
                : `translateY(${-100 - 70 * (selected - 1)}%)`,
            transition: responsive
              ? 'transform 590ms linear'
              : 'transform 290ms linear',
            willChange: 'transform',
          }}
        >
          {imageList.map(
            (image, index) =>
              index !== 0 && (
                <Tab
                  key={index}
                  responsive={responsive}
                  width={width}
                  height={height}
                  index={index}
                  image={image}
                  inAnimation={inAnimation}
                  selected={selected}
                  selectedHeight={selectedHeight}
                  deselectedHeight={deselectedHeight}
                  initialHeight={initialHeight}
                  updateFocus={this.updateFocus}
                />
              )
          )}
        </Box>
      </Box>
    );
  }
}
