import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './../arrow/arrow.css';

interface landingFormat {
  width: number;
  responsive: boolean;
  index: number;
  selected: number;
  updateFocus: Function;
  selectedHeight: string;
  deselectedHeight: string;
  initialHeight: string;
}

export function Landing(props: landingFormat) {
  const delimiter = props.responsive ? ' ˑ ' : '\n';
  const hotLinkStyles = {
    textShadow:
      '0 0 50px #000, 0 0 25px #000, 0 0 15px #000, 0 0 10px #000, 0 0 5px #000, 0 0 3px #000, 0 0 2px #000, 0 0 1px #000',
    overflow: 'visible',
    whiteSpace: 'pre-line',
  }

  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: props.responsive ? 'center' : 'space-evenly',
        gap: props.responsive ? 12 : 6,
        overflow: 'hidden',
      }}
    >
      <Typography
        variant="h1"
        align="center"
        noWrap={props.responsive}
        color="#FFF"
        width="100%"
        gutterBottom
        fontSize={
          props.responsive ? props.width / 14 : props.width / 7
        }
        fontFamily="Orbitron"
        sx={{
          textShadow:
            '0 0 50px #000, 0 0 25px #000, 0 0 15px #000, 0 0 10px #000, 0 0 5px #000, 0 0 3px #000, 0 0 2px #000, 0 0 1px #000',
          overflow: 'visible',
        }}
      >
        METRIC SOUNDS
      </Typography>
      <Box
        alignItems="center"
        justifyContent={props.responsive ? 'space-evenly' : 'center'}
        width="100%"
        fontFamily="Orbitron"
        sx={{
          overflow: 'visible',
          display: 'flex',
          flexDirection: props.responsive ? 'row' : 'column',
        }}
      >
        <Typography
          variant="h2"
          noWrap
          color="#FFF"
          fontSize={
            props.responsive ? props.width / 30 : props.width / 15
          }
          fontFamily="Orbitron"
          sx={hotLinkStyles}
          onClick={() => {
            props.updateFocus(1);
          }}
        >
          Weddings
        </Typography>
        <Typography
          variant="h2"
          noWrap
          color="#FFF"
          fontSize={
            props.responsive ? props.width / 30 : props.width / 15
          }
          fontFamily="Orbitron"
          sx={hotLinkStyles}
        >
          {delimiter}
        </Typography>
        <Typography
          variant="h2"
          noWrap
          color="#FFF"
          fontSize={
            props.responsive ? props.width / 30 : props.width / 15
          }
          fontFamily="Orbitron"
          sx={hotLinkStyles}
          onClick={() => {
            props.updateFocus(2);
          }}
        >
          DJ Service
        </Typography>
        <Typography
          variant="h2"
          noWrap
          color="#FFF"
          fontSize={
            props.responsive ? props.width / 30 : props.width / 15
          }
          fontFamily="Orbitron"
          sx={hotLinkStyles}
        >
          {delimiter}
        </Typography>
        <Typography
          variant="h2"
          noWrap
          color="#FFF"
          fontSize={
            props.responsive ? props.width / 30 : props.width / 15
          }
          fontFamily="Orbitron"
          sx={hotLinkStyles}
          onClick={() => {
            props.updateFocus(3);
          }}
        >
          Live Bands
        </Typography>
        <Typography
          variant="h2"
          noWrap
          color="#FFF"
          fontSize={
            props.responsive ? props.width / 30 : props.width / 15
          }
          fontFamily="Orbitron"
          sx={hotLinkStyles}
        >
          {delimiter}
        </Typography>
        <Typography
          variant="h2"
          noWrap
          color="#FFF"
          fontSize={
            props.responsive ? props.width / 30 : props.width / 15
          }
          fontFamily="Orbitron"
          sx={hotLinkStyles}
          onClick={() => {
            props.updateFocus(4);
          }}
        >
          Production
        </Typography>
      </Box>
      <Box
        height={0}
        onClick={() => {
          props.updateFocus(1);
        }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          transform: props.responsive ? 'translateY(15vh)' : '',
        }}
      >
        <Box className="arrowSliding">
          <Box className="arrow"></Box>
        </Box>
        <Box className="arrowSliding delay2">
          <Box className="arrow"></Box>
        </Box>
      </Box>
    </Box>
  );
}
