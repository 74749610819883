import React from 'react';
import { Wrapper } from './components';
import '@fontsource/orbitron';
import '@fontsource/roboto';
import './App.css';

function App() {
  return <Wrapper />;
}

export default App;
