import React from 'react';

import Paper from '@mui/material/Paper';

import { Band, Dj, Landing, Production, Wedding } from '../.';

interface tabFormat {
    width: number;
    height: number;
    index: number;
    responsive: boolean;
    selected: number;
    inAnimation: boolean;
    updateFocus: Function;
    selectedHeight: string;
    deselectedHeight: string;
    initialHeight: string;
    image: string;
}

export function Tab(props: tabFormat) {
    return (
        <Paper
            onClick={() => {
                props.index !== 0 &&
                    props.index !== props.selected &&
                    props.updateFocus(props.index);
            }}
            className="shadow"
            sx={{
                zIndex: 100 - props.index,
                position: 'relative',
                width: '100%',
                height: props.index === 0 ? '100%' : props.selectedHeight,
                WebkitTransform:
                    props.index === 0 || props.index <= props.selected
                        ? 'translateY(0%)'
                        : `translateY(-${
                              (600 / 7) * (props.index - props.selected)
                          }%)`,
                WebkitTransition: props.responsive
                    ? '-webkit-transform 590ms linear'
                    : '-webkit-transform 290ms linear',
                MozTransform:
                    props.index === 0 || props.index <= props.selected
                        ? 'translateY(0%)'
                        : `translateY(-${
                              (600 / 7) * (props.index - props.selected)
                          }%)`,
                MozTransition: props.responsive
                    ? '-moz-transform 590ms linear'
                    : '-moz-transform 290ms linear',
                transform:
                    props.index === 0 || props.index <= props.selected
                        ? 'translateY(0%)'
                        : `translateY(-${
                              (600 / 7) * (props.index - props.selected)
                          }%)`,
                transition: props.responsive
                    ? 'transform 590ms linear'
                    : 'transform 290ms linear',
                willChange: 'transform',
                borderTop: 0,
                borderBottom:
                    props.selected + 3 >= props.index &&
                    props.selected <= props.index
                        ? 3
                        : 0,
                borderColor: '#000',
                backgroundImage: `url(${props.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                boxShadow:
                    props.index === 0
                        ? props.responsive
                            ? '100px 100px 150px #000 inset, -100px -100px 150px #000 inset'
                            : '30px 30px 30px #000 inset, -30px -30px 30px #000 inset'
                        : props.index >= props.selected ||
                            (props.index >= props.selected - 1 &&
                                props.inAnimation)
                          ? props.responsive
                              ? '0 20px 30px #000, 0 20px 30px #000'
                              : '0 20px 30px #000, 0 10px 15px #000'
                          : '0 0 0 #000',
            }}
            square
        >
            {props.index === 0 ? (
                <Landing
                    width={props.width}
                    responsive={props.responsive}
                    index={props.index}
                    selected={props.selected}
                    updateFocus={props.updateFocus}
                    selectedHeight={props.selectedHeight}
                    deselectedHeight={props.deselectedHeight}
                    initialHeight={props.initialHeight}
                />
            ) : props.index % 4 === 1 ? (
                <Wedding
                    width={props.width}
                    height={props.height}
                    responsive={props.responsive}
                    index={props.index}
                    inAnimation={props.inAnimation}
                    selected={props.selected}
                    updateFocus={props.updateFocus}
                    selectedHeight={props.selectedHeight}
                    deselectedHeight={props.deselectedHeight}
                    initialHeight={props.initialHeight}
                />
            ) : props.index % 4 === 2 ? (
                <Dj
                    width={props.width}
                    height={props.height}
                    responsive={props.responsive}
                    index={props.index}
                    inAnimation={props.inAnimation}
                    selected={props.selected}
                    updateFocus={props.updateFocus}
                    selectedHeight={props.selectedHeight}
                    deselectedHeight={props.deselectedHeight}
                    initialHeight={props.initialHeight}
                />
            ) : props.index % 4 === 3 ? (
                <Band
                    width={props.width}
                    height={props.height}
                    responsive={props.responsive}
                    index={props.index}
                    inAnimation={props.inAnimation}
                    selected={props.selected}
                    updateFocus={props.updateFocus}
                    selectedHeight={props.selectedHeight}
                    deselectedHeight={props.deselectedHeight}
                    initialHeight={props.initialHeight}
                />
            ) : props.index % 4 === 0 ? (
                <Production
                    width={props.width}
                    height={props.height}
                    responsive={props.responsive}
                    index={props.index}
                    inAnimation={props.inAnimation}
                    selected={props.selected}
                    updateFocus={props.updateFocus}
                    selectedHeight={props.selectedHeight}
                    deselectedHeight={props.deselectedHeight}
                    initialHeight={props.initialHeight}
                />
            ) : null}
        </Paper>
    );
}
